<template>
  <section>
    <img src="../assets/ad1.png" class="ad1"/>
    <div class="videos">
      <div class="highlight">
        <img :src="mainVideo.thumbnail"
          @click="start"
          v-show="!play"/>
        <video :src="mainVideo.url"
          @click="pause"
          v-show="play"
          ref="video"
        >
        </video>
      </div>
      <div class="preview">
        <div class="preview_items">
          <div class="preview_item">
            <img src="../assets/cristiano.webp" alt="">
            <h3>O melhor futebol do mundo</h3>
          </div>
          <div class="preview_item">
            <img src="../assets/cristiano.webp" alt="">
            <h3>O melhor futebol do mundo</h3>
          </div>
          <div class="preview_item">
            <img src="../assets/cristiano.webp" alt="">
            <h3>O melhor futebol do mundo</h3>
          </div>
          <div class="preview_item">
            <img src="../assets/cristiano.webp" alt="">
            <h3>O melhor futebol do mundo</h3>
          </div>
        </div>
        <SliderButtons :currentSlide="0"
          class="preview_buttons"
          :nrOfSlides="2" />
      </div>
      <img src="../assets/betad2.png" class="ad2"/>
    </div>
  </section>
</template>

<script>
import video1 from '../assets/images/video-thumbnail.png'
import SliderButtons from '@/components/Slider/Buttons.vue';

export default {
  name: 'VideosComponent',
  components: {
    SliderButtons,
  },
  props: {
  },
  data() {
    return {
      mainVideo: {
        id: 1,
        thumbnail: video1,
        url: `${process.env.VUE_APP_HOSTNAME}/wp-content/uploads/2023/07/Lifiting-Vegan-V2_Oraculo.mp4`,
      },
      play: false,
    }
  },
  methods: {
    start() {
      this.play = true
      this.$refs.video.play()
    },
    pause() {
      this.play = false
      this.$refs.video.pause()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
section {
  --padding-sides: calc((100vw - 1400px) / 2);
  background-image: url('../assets/videos-bg.png');
  background-size: cover;
  padding: 8rem var(--padding-sides);
  max-width: 100%;
  row-gap: 4rem;

  .ad1 {
    width: 100%;
  }
  .videos {
    display: grid;
    align-items: center;
    margin-top: 2rem;
    grid-template-columns: min-content 1fr min-content;
    height: calc( ( 100vw - 2 * var(--padding-sides) ) * 0.24 );
  }
  .highlight {
    object-fit: contain;
    min-height: 0;
    height: 100%;
    img, video {
      height: 100%;
    }
  }
  .preview {
    flex-basis: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
    margin-left: -4rem;
    display: flex;
    position: relative;
    &_buttons {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: fit-content;
      bottom: -1rem;
    }
    &_items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      width: 100%;
      min-height: 0;
      margin: auto;
    }
    &_item {
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        object-fit: cover;
      }
      h3 {
        color: white;
        font-size: .6rem;
      }
    }
  }
  .ad2 {
    object-fit: contain;
    margin: 0 0 0 auto;
    height: 100%;
    min-height: 0;
  }
}
</style>
