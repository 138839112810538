<template>
  <div>
    <img src="../assets/widget1.png"/>
  </div>
</template>

<script>
export default {
  name: 'WidgetOne',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div {
  width: 100%;
  display: flex;
  img {
    margin: auto;
    max-width: 100%;
  }
}
</style>
