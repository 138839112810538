<template>
  <section class="news">
    <div class="news_menu">
      <h2><span>Notícias</span></h2>
      <div class="menu" :style="{'--selected-category': selectedCategoryIndex}">
        <button class="menu_button"
          v-for="category, index in categories"
          :class="{ active: selectedCategoryIndex === index }"
          @click="selectedCategoryIndex = index"
          :key="category.id">
          <span v-text="category.name"></span>
        </button>
      </div>
    </div>

    <div class="news_track">
      <div class="news_item" v-for="post in posts" :key="post.id">
        <div class="news_highlightImage">
          <img :src="post.highlight_image" alt="news1">
        </div>
        <div class="news_meta">
          <a href="#" class="newsMeta_category" v-for="category in post.categories" :key="category">
            <span v-text="category"></span>
          </a>
        </div>
        <div class="newsMeta_description">
          <h3 v-text="post.title"></h3>
          <p v-text="post.excerpt"></p>
        </div>
        <div class="news_actions">
          <a href="#" class="button">Ver mais</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Asset1 from '../assets/news1.png';
import Asset2 from '../assets/news2.png';
import Asset3 from '../assets/news3.png';

export default {
  name: 'NewsComponent',
  props: {
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          title: "Benfica-Sporting: Ganha o dérbi quem está pior: Verdade ou mito?",
          excerpt: "Domingo, a partir das 18h00. Portugal vai parar para assistir a mais um Benfica-Sporting, referente à 16ª jornada da Liga portuguesa, naquela que será a 315ª edição do derbi eterno...",
          url: "/",
          highlight_image: Asset1,
          published_at: "2021-01-29T12:00:00.000Z",
          categories: ["I Liga"],
        },
        {
          id: 2,
          title: "Jogo Grande Marca o Regresso da Liga em Portugal",
          excerpt: "Está de regresso o principal campeonato do futebol em Portugal, com os jogos da 14ª jornada da Liga, depois de uma paragem de cerca de mês e meio, devido à realização do Campeonato do Mundo do Catar....",
          url: "/",
          highlight_image: Asset2,
          published_at: "2021-01-29T12:00:00.000Z",
          categories: ["I Liga"],
        },
        {
          id: 3,
          title: "Agora já sem Mundial, há muitos jogos grandes para ver até ao Natal",
          excerpt: "Terminado o Mundial, as atenções do mundo do futebol voltam a centrar-se nos clubes. Houve países que não pararam a actividade por completo caso de Portugal – mas na grande maioria foi período...",
          url: "/",
          highlight_image: Asset3,
          published_at: "2021-01-29T12:00:00.000Z",
          categories: ["I Liga"],
        }
      ],
      categories: [
        {
          id: 1,
          name: "Futebol Nacional"
        },
        {
          id: 2,
          name: "Futebol Internacional"
        },
        {
          id: 3,
          name: "Modalidades"
        },
        {
          id: 4,
          name: "Casino"
        },
        {
          id: 5,
          name: "Notícias Betano"
        },
        {
          id: 6,
          name: "Patrocínio Betano"
        }
      ],
      selectedCategoryIndex: 0,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.news {
  width: 90%;
  margin: auto;
  padding: 4rem 0;
  --menu-height: 5rem;
  &_actions {
    .button {
      // Layout
      display: inline-flex;
      padding: 0.59088rem 1.18175rem;
      align-items: flex-start;
      gap: 0.39394rem;
      // Style
      border: 0.63px solid #F60;
      background: rgba(11, 21, 33, 0.30);
      box-shadow: 0px 0px 5.042016983032227px 0px #F60;
      // Typography
      color: #F60;
      text-align: center;
      font-family: Glober;
      font-size: 0.51206rem;
      font-style: normal;
      font-weight: 900;
      line-height: 1; /* 0% */
      text-transform: uppercase;
      text-decoration: none;
      // Effects
    }
  }

  &_menu {
    margin: 4rem 0 2rem;
  }
  &_highlightImage {
    min-width: 0;
    &, img {
      width: 100%;
    }
  }
  &_meta {
    display: flex;
  }
}
.news_track {
  display: flex;
  gap: 3rem;
  padding: 0 2rem;
}

.news_item {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h3 {
    color: #F60;
    font-family: Glober;
    font-size: 1.75rem;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  p {
    --max-lines: 3;
    --lh: #{0.875rem * 2};
    color: #6E85A0;
    font-family: Glober;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: var(--lh);
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
  }
}

.newsMeta {
  &_category {
    // Layout
    display: grid;
    place-items: center;
    width: 7rem;
    height: 2.1875rem;
    flex-shrink: 0;
    //Style
    border: 1px solid #F60;
    box-shadow: 0px 0px 4px 0px #F60;
    // Typography
    color: #FF6600;
    font-family: Glober;
    font-size: 0.70613rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    span {
      display: inline-block;
      transform: translateY(.15em);
    }
  }
  &_description {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    p {
      margin-top: auto;
    }
  }
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #FF6600;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  background-color: #1E2D40;
  margin-bottom: calc(0px - var(--menu-height) / 2);
  padding: 0 1rem;
  height: calc(var(--menu-height) / 2);
  margin-left: .5rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 4;
  // Typography
  color: #F60;
  font-family: Glober;
  font-size: 1.321rem;
  font-style: italic;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  span {
    display: inline-block;
    transform: translateY(.1em);
  }
}

.menu {
  --nr-of-items: 6;
  --selected-category: 0;
  display: flex;
  height: var(--menu-height);
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    width: calc((100% / var(--nr-of-items)) + 1px);
    height: 100%;
    background: #ff6600;
    display: block;
    position: absolute;
    top: 0;
    z-index: 0;
    transition: left .2s ease-out;
    left: calc( (100% / var(--nr-of-items)) * var(--selected-category) - 1px);
  }
  &_button {
    text-transform: uppercase;
    background: rgba(255, 102, 0, 0.10);
    color: #ff6600;
    font-style: italic;
    border: none;
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    padding: calc(var(--menu-height) / 2) 1rem 0;
    z-index: 1;
    // Typography
    text-align: center;
    font-family: Glober;
    font-size: 0.77638rem;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    // Effects
    cursor: pointer;
    transition: .2s ease-out;
    transition-property: background, color;
    &:hover {
      background: rgba(255, 102, 0, 0.20);
    }
    &.active {
      // background: #ff6600;
      color: #fff;
      &:hover {
      }
    }
    &:not(:last-child) {
      &::after {
        --border-height: calc((var(--menu-height) / 2) * .8);
        content: '';
        width: 1px;
        height: var(--border-height);
        background: #ff6600;
        display: block;
        position: absolute;
        right: 0;
        bottom: calc( ((var(--menu-height) / 2) - var(--border-height) ) / 2);
      }
    }
    span {
      display: inline-block;
      transform: translateY(.1em);
    }
  }
}

</style>
