<template>
  <MenuComponent :canLoad="canLoad" ref="menu"/>
  <router-view @ready="canLoad = true" :style="{'--menu-height': navigationHeight + 'px'}"/>
  <FooterComponent/>
</template>

<script>
import MenuComponent from './components/Menu.vue'
import FooterComponent from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    FooterComponent,
    MenuComponent,
  },
  data() {
    return {
      canLoad: false,
      navigationHeight: 0,
    }
  },
  watch: {
    canLoad() {
      if( !this.canLoad ) {
        return
      }
      this.$nextTick().then( () => {
        this.navigationHeight = this.$refs.menu.$el.offsetHeight;
      })
    },
  },
}
</script>


<style lang="scss">
@import "@/assets/scss/_colors.scss";

:root {
  --z-footer: 100;
  --background-color: #{$color-background};
  --menu-height: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  min-height: 100%;
  background: var(--background-color);
  max-width: 100%;
}
#app {
  font-family: Glober, Helvetica, Arial, sans-serif;
  width: 100%;
  overflow: hidden;
}

nav {

  a {
    font-weight: bold;
    &.router-link-exact-active {
    }
  }
}
</style>
