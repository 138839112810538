import axios from '@/load_axios';

const posts = {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    slug: (state) => (slug) => {
      return state.all.find( post => post.slug == slug)
    }
  },
  mutations: {
    add(state, posts) {
      for(let post of posts) {
        const index = state.all.findIndex( storedPost => storedPost.id === post.id)
        if(index === -1) {
          state.all.push(post)
        } else {
          state.all.splice(index, 1, post)
        }
      }
    },
  },
  actions: {
    async getAll({commit}) {
      const res = await axios.get(`${process.env.VUE_APP_HOSTNAME}/wp-json/wp/v2/posts`)
      console.log(res)
      commit('add', res.data)
    },
    async getOne({commit}, slug) {
      const res = await axios.get(`${process.env.VUE_APP_HOSTNAME}/wp-json/wp/v2/posts?slug=${slug}`)
      commit('add', res.data)
      return res.data
    }
  },
}

export default posts
