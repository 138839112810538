<template>
  <section :class="{invisible: !visible}">
    <div class="partner">
      <h2 class="partner-title"><strong>Patrocinador</strong> Ofícial</h2>
      <div class="partner-logos">
        <img src="../assets/images/SCP.png"/>
        <img src="../assets/images/SLB.png"/>
        <img src="../assets/images/FCP.png"/>
        <img src="../assets/images/Herois_Betano.png"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SponsorComponent',
  props: {
  },
  data() {
    return {
      intersectionObserver: null,
      visible: true,
    }
  },
  methods: {
    isVisible(val) {
      this.visible = val[0].isIntersecting
    },
  },
  mounted() {
    this.intersectionObserver = new IntersectionObserver(this.isVisible, {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    })
    this.intersectionObserver.observe(this.$el)
  },
  unmounted() {
    this.intersectionObserver.disconnect()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/colors';
$padding: 200px;
section {
  z-index: calc(var(--z-footer) + 1);
  position: relative;
  padding: 6rem 5% 0;
  background-image: url('../assets/images/sponsor-bg-big.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  margin-bottom: -3rem;
  transition: .5s opacity ease-out;
  &.invisible {
    opacity: 0.1;
  }
}
.partner {
  width: 100%;
  padding: 4rem $padding 8rem;
  background-color: #F7F7F9;
  width: 90%;
  margin: 0 auto;
  background-image: url('../assets/images/sponsor-bg.png');
  background-size: cover;
  background-position: bottom left;
  &-logos {
    display: flex;
    gap: $padding;
  }
  &-title {
    font-weight: 700;
    color: #1B1B1B;
    margin-bottom: 4rem;
    font-style: italic;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    color: $color-betano;
  }
  img {
    margin: auto;
    flex-grow: 1;
    transition: transform .2s ease-out;
    &:hover {
      transform: scale(1.15);
    }
  }
}
</style>
