<template>
  <div class="select" @mouseleave="open = false">
    <a href="#" @click.prevent="open = !open">Selecionar Mês</a>
    <ul v-show="open">
      <li><a href="#">Janeiro 2023</a></li>
      <li><a href="#">Fevereiro 2023</a></li>
      <li><a href="#">Março 2023</a></li>
      <li><a href="#">Abril 2023</a></li>
      <li><a href="#">Maio 2023</a></li>
      <li><a href="#">Junho 2023</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FooterSelect',
  props: {
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/colors';

$font-size: .75rem;
a {
  color: $color-white;
  text-decoration: none;
}

.select {
  font-size: $font-size;
  position: relative;
  > a {
    border: 2px solid $color-white;
    padding: 1em;
    display: flex;
    &::after {
      content: '';
      background-image: url('@/assets/images/bullet.svg');
      height: 1em;
      width: .35em;
      display: block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      padding: 0;
      margin: 0 0 0 .5em;
      transform: rotate(90deg);
    }
  }
}
ul {
  position: absolute;
  bottom: calc(100% - 2px);
  background-color: $color-betano;
  padding: 0;
  list-style: none;
  min-width: 100%;
  border: 2px solid $color-white;
  z-index: 1000;
  filter: drop-shadow(0 0px 6px $color-betano);
  li {
    padding: 1em;
    &:not(:last-child) {
      border-bottom: 1px solid $color-white;
    }
  }
}
</style>
