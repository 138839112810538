<template>
    <a :href="to"
        class="button external"
        :class="mode"
        target="_blank"
        v-if="external"
        rel="noopener noreferrer">
        <span v-text="text"></span>
    </a>
    <router-link :to="to"
        class="button"
        :class="mode"
        v-else>
        <span v-text="text"></span>
    </router-link>
</template>

<script>
export default {
    name: 'BetButton',
    props: {
        to: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        external: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'normal'
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.button {
    // Layout
    display: flex;
    padding: 0.75rem 1.875rem;
    align-items: flex-start;
    width: fit-content;
    // Style
    border: 1px solid #FF6600;
    background: rgba(11, 21, 33, 0.30);
    box-shadow: 0px 0px 8px 0px #F60;
    // Typography
    color: #F60;
    text-align: center;
    font-family: Glober;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1em; /* 0% */
    text-transform: uppercase;
    text-decoration: none;
    // Effects
    transition: 0.2s ease-in-out;
    transition-property: background, box-shadow, color, transform;

    &.black {
        color: $color-black;
        border-color: $color-black;
        background-color: rgba($color-black, 0);
        border-width: 2px;
        &:hover {
            background-color: $color-black;
        }
    }

    &:hover {
        background: #ff6600;
        box-shadow: 0px 0px 0px 0px #F60;
        color: white;
    }

    span {
        transform: translateY(.1em);
    }
}

</style>