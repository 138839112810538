<template>
<nav v-if="canLoad">
  <router-link to="/"><img src="../assets/logo.png"/></router-link>
  <router-link to="/">Futebol</router-link>
  <router-link to="/">Modalidades</router-link>
  <router-link to="/">Casino</router-link>
  <router-link to="/">Patrocínios</router-link>
  <router-link to="/">Heróis Betano</router-link>
  <router-link to="/">Betanomag</router-link>
  <router-link to="/">Tutorial</router-link>
</nav>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    canLoad: {
      type: Boolean,
      default: true,
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

nav {
  padding: 3.75rem 5.75rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(
    180deg,
    rgba(0,0,0,1) 0%,
    rgba(0,0,0,.85) 40%,
    rgba(0,0,0,0) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  // Effects
  animation: slideIn 1s;
  animation-delay: 1s;
  animation-fill-mode: both;
  a {
    font-weight: bold;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    &.router-link-exact-active {
    }
  }
}
</style>
