<template>
  <section :class="{vertical}">
    <div class="highlight"
      v-for="post in posts"
      :key="post.id">
      <div class="highlight_content">
        <div alt="" class="highlight_image">
          <img :src="post.highlight_image" alt="">
        </div>
        <h2 v-text="post.title" class="highlight_title"></h2>
        <BetButton
          :to="post.url"
          text="Ver mais"
          :mode="buttonMode"
          class="highlight_button"/>
      </div>
    </div>
  </section>
</template>

<script>
import Image1 from '@/assets/home-img.png';
import Image2 from '@/assets/cristiano.webp';

import BetButton from '@/components/BetButton.vue';

export default {
  name: 'HighlightsComponent',
  components: {
    BetButton,
  },
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
    buttonMode: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          title: "Inter é favorito, mas quem tem leão pode caçar qualquer troféu",
          subtitle: "Dérbi na Supertaça de Itália",
          url: "/",
          highlight_image: Image1,
        },
        {
          id: 2,
          title: "Cristiano Ronaldo",
          subtitle: "O melhor jogador do mundo",
          url: "/",
          highlight_image: Image2,
        }
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/colors';
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

section {
  --image-offset: 3rem;
  --content-padding: 1.3rem;
  position: relative;
  z-index: 2;
  height: fit-content;
  background-image: url('@/assets/images/highlights-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  display: flex;
  justify-content: flex-end;
  padding: 0 1.3rem 4rem;
  gap: 3%;
  width: 100%;
  &.vertical {
    background-image: none;
    padding: 0;
    gap: 4rem;
    flex-direction: column;

    .highlight {
      width: 100%;
      margin-top: calc(var(--image-offset) - var(--content-padding));
      &_content {
        background-color: transparent;
        border: 2px solid $color-betano;
        box-shadow: 0px 0px 8px rgba($color-betano, .5);
      }
      &_image {
        box-shadow: none;
      }
      &_button {
        border-color: $color-white;
        color: $color-white;
        box-shadow: none;
        background: transparent;
      }
    }
  }
}

.highlight {
  width: 30%;
  height: min-content;
  animation: slideInFromLeft 1s;
  animation-delay: .5s;
	animation-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  animation-fill-mode: both;
  &+& {
    animation-delay: .75s;
  }
  &:hover {
    // animation-fill-mode: both;
    transform: translate(0, -5px);
    // box-shadow: 0px 0px 8px 0px #FF6600;
  }

  &_content, &_image {
    transition: .2s ease-out;
    transition-property: transform, filter, box-shadow;
  }

  &_content {
    width: 100%;
    height: 100%;
    background-color: #FF6600;
    padding: var(--content-padding);
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: grid;
    gap: 1.2rem;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas: 
      "image image"
      "title title"
      "button share";
    perspective: 100px;
    &:hover {
      transform: scale(1.05) translate(0, 5px);
      filter: drop-shadow(0px 0px 32px #FF6600);
      & > .highlight_image {
        transform: scale(1.05) rotate3d(1, 0, 0, 1deg);
        // rotate: x 45deg;
        img {
          transition-timing-function: linear;
          transition-duration: .2s;
          // transform: scale(1.25);
        }
      }
    }
  }

  &_image {
    grid-area: image;
    position: relative;
    width: 100%;
    min-height: 0;
    height: 0;
    padding: #{9 / 16 * 100%} 0 0;
    z-index: 1;
    margin-top: -20%;
    margin-top: calc(-1 * var(--image-offset));
    box-shadow: 0px 4px 10px 0px rgba(117, 19, 19, 0.70);
    overflow: hidden;
    transform-origin: bottom center;
    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      transition-duration: .5s;
      transition-property: transform;
    }
  }
  &_title {
    $font-size: 0.9375rem;
    --max-lines: 2;
    --font-size: $font-size;
    --lh: #{1.2 * $font-size};
    line-height: var(--lh);
    height: calc(var(--lh) * var(--max-lines));
    grid-area: title;
    // Typography
    font-size: 0.9375rem;
    font-style: italic;
    font-weight: normal;
    text-transform: uppercase;
    color: #fff;
  }
  &_button {
    grid-area: button;
    font-size: 0.62669rem;
    padding: 0.4375rem 0.625rem;
    // border-color: black;
  }
}

</style>
