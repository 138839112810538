<template>
  <div class="home">
    <HeroHeader @ready="loadedHeroHeader = true" />
    <HighlightsComponent v-if="canLoadHighlights"/>
    <WidgetOne/>
    <NewsComponent/>
    <VideosComponent/>
    <SponsorComponent/>
  </div>
</template>

<script>
// @ is an alias to /sr
import HeroHeader from '@/components/HeroHeader.vue'
import HighlightsComponent from '@/components/Highlights.vue';
import NewsComponent from '@/components/News.vue'
import SponsorComponent from '@/components/Sponsor.vue'
import VideosComponent from '@/components/VideosComponent.vue';
import WidgetOne from '@/components/WidgetOne.vue';

export default {
  name: 'HomeView',
  components: {
    HeroHeader,
    HighlightsComponent,
    NewsComponent,
    SponsorComponent,
    VideosComponent,
    WidgetOne,
  },
  data() {
    return {
      loadedHeroHeader: false,
    }
  },
  computed: {
    canLoadHighlights() {
      return this.loadedHeroHeader;
    },
    loaded() {
      return this.loadedHeroHeader;
    }
  },
  watch: {
    loaded() {
      this.$emit('ready');
    }
  }
}
</script>
