<template>
  <footer>
    <div class="columns">
      <div class="columns_column column">
        <h3>Sobre a BETANO</h3>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit praesentium aperiam.</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit praesentium aperiam.</p>
      </div>
      <div class="columns_column columns_column-2 column column-2">
        <h3>Informação Útil</h3>
        <ul>
          <li><a href="#">Betano.pt</a></li>
          <li><a href="#">Apostas ao Vivo</a></li>
          <li><a href="#">Resultados ao Vivo</a></li>
        </ul>
        <ul>
          <li><a href="#">Estatísticas</a></li>
          <li><a href="#">Registar</a></li>
          <li><a href="#">Contacto</a></li>
        </ul>
      </div>
      <div class="columns_column column">
        <h3>Arquivos</h3>
        <FooterSelect/>
        <h3>Siga-nos</h3>
        <div class="social">
          <a href="#">
            <img src="../assets/images/facebook.svg" alt="facebook">
          </a>
          <a href="#">
            <img src="../assets/images/x.svg" alt="twitter">
          </a>
          <a href="#">
            <img src="../assets/images/youtube.svg" alt="youtube">
          </a>
          <a href="#">
            <img src="../assets/images/instagram.svg" alt="instagram">
          </a>
          <a href="#">
            <img src="../assets/images/telegram.svg" alt="telegram">
          </a>
        </div>
      </div>
    </div>
    <p><strong>Copyright 2023 BETANO.pt |</strong> todos os direitos reservados</p>
  </footer>
</template>

<script>
import FooterSelect from './Footer/Select';

export default {
  name: 'FooterComponent',
  components: {
    FooterSelect
  },
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/scss/colors';

$font-size: .75rem;

footer {
  width: 100%;
  background-color: $color-betano;
  padding: 4rem 15%;
  img {
    width: 100%;
    min-width: 0;
  }
  > p {
    margin: 4rem auto 0;
    width: fit-content;
    color: #000;
    font-family: Glober;
    font-size: 0.80388rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    strong {
      font-weight: 700;
    }
  }
}

.columns {
  display: flex;
  justify-content: center;
  gap: 10%;

  &_column {
    flex-basis: 0;
    flex-grow: 1;
    &-2 {
      flex-grow: 2;
    }
    p {
      color: $color-white;
      line-height: 2;
      text-align: left;
      font-size: $font-size;
      &:not(:last-child) {
        margin-bottom: 2em;
      }
    }
    ul {
      color: $color-white;
      list-style: none;
      font-size: $font-size;
      li {
        padding: 1em 0 1em;
        border-bottom: 2px solid $color-white;
        display: flex;
        &::before {
          content: '';
          background-image: url('@/assets/images/bullet.svg');
          height: 1em;
          width: .35em;
          display: block;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          padding: 0;
          margin: 0 .5em 0 0;
        }
        a {
          color: $color-white;
          text-decoration: none;
        }
      }
    }
  }
}

.select {
  font-size: $font-size;
  > a {
    border: 2px solid $color-white;
    padding: 1em;
    display: flex;
    color: $color-white;
    text-decoration: none;
    &::after {
      content: '';
      background-image: url('@/assets/images/bullet.svg');
      height: 1em;
      width: .35em;
      display: block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      padding: 0;
      margin: 0 0 0 .5em;
      transform: rotate(90deg);
    }
  }
  ul {
    display: none;
  }
}

.column {
  h3 {
    grid-column: 1 / -1;
    margin: 1.5rem 0 1rem;
    color: $color-black;
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
    text-align: left;
  }
  &-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 3rem;
  }
}

.social {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  a {
    height: 20px;
    width: auto;
    display: block;
    transition: .2s ease-out transform;
    &:hover {
      transform: scale(1.15);
    }
    img {
      height: 100%;
      width: auto;
    }
  }
}
</style>
