<template>
  <header :class="{ready}">
    <TransitionGroup name="slide-fade" mode="out-in">
      <div class="slide"
        v-for="post in posts"
        :class="{active: currentSlide.id == post.id}"
        :key="post.id"
        v-show="currentSlide.id == post.id">
        <img :src="post.images.original" alt="" @load="imgLoaded">
      </div>
    </TransitionGroup>

    <SlideInfo :posts="posts"
      v-if="ready"
      :currentSlideIndex="currentSlideIndex"
      @update:currentSlideIndex="currentSlideIndex = $event" />

  </header>
</template>

<script>
import SlideInfo from '@/components/HeroHeader/SlideInfo.vue';

export default {
  name: 'HeroHeader',
  components: {
    SlideInfo,
  },
  props: {
  },
  data() {
    return {
      currentSlideIndex: 0,
      canLoad: true,
      loadedImagesNumber: 0,
    }
  },
  methods: {
    imgLoaded() {
      this.loadedImagesNumber++;
    },
  },
  computed: {
    currentSlide() {
      if( this.posts.length ) {
        return this.posts[this.currentSlideIndex];
      }
      return null
    },
    posts() {
      return this.$store.state.posts.all
    },
    ready() {
      if( this.posts.length == 0 ) return false
      return this.loadedImagesNumber == this.posts.length && this.canLoad
    },
  },
  watch: {
    ready() {
      if( this.ready ) {
        this.$emit('ready');
      }
    }
  },
  mounted() {
    console.log("Mounted Hero Header")
    this.$store.dispatch('posts/getAll')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/_colors.scss";
@import "@/assets/scss/_effects.scss";

header {
  width: 100%;
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ( 9 / 16 * 100vw );
  margin-bottom: -10%;
  transition: 1s;
  transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
  transition-property: opacity, transform;

  &:not(.ready) {
    opacity: 0;
    transform: translateY(-50%);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 1px);
    background:
      linear-gradient(
        0deg,
        rgba($color-background, 1) 0%,
        rgba($color-background, .5) 40%,
        rgba($color-background, 0) 100%
      );
    z-index: 10;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slide {
    background-image: var(--background-image);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    &.active {
      z-index: 2;
    }
  }
}
</style>
