<template>
<div class="slider-controls" :class="{out}">
    <h2 v-text="title" :class="{out}" ref="title"></h2>
    <h4 v-text="subTitleText"></h4>
    <BetButton :to="currentSlide.link"
        class="slider-button"
        text="Ver mais" />
    <!-- <div class="slider-buttons">
        <div class="slider-control"
            v-for="post, index in posts"
            :key="post.id"
            :class="{active: currentSlide.id == post.id}"
            @click="$emit('update:currentSlideIndex', index)">
        </div>
    </div> -->
    <SliderButtons :currentSlide="currentSlideIndex"
        :nrOfSlides="posts.length"
        @update:currentSlide="$emit('update:currentSlideIndex', $event )" />
</div>
</template>

<script>
import BetButton from '@/components/BetButton.vue';
import SliderButtons from '@/components/Slider/Buttons.vue';

export default {
name: 'SlideInfo',
components: {
    BetButton,
    SliderButtons,
},
props: {
    posts: {
        type: Array,
        required: true,
    },
    currentSlideIndex: {
        type: Number,
        required: true,
    },
},
data() {
    return {
        activeSlideIndex: 0,
        nextActiveSlideIndex: 0,
        subTitleLength: 0,
        out: false,
    }
},
computed: {
    currentSlide() {
        return this.posts[this.activeSlideIndex];
    },
    title() {
        return this.currentSlide.title.rendered;
    },
    subTitle() {
        return this.currentSlide.fields.subtitle;
    },
    subTitleText() {
        return this.subTitle.substring(0, this.subTitleLength);
    },
},
watch: {
    currentSlideIndex: {
        immediate: false,
        async handler() {
            this.out = true;
            this.nextActiveSlideIndex = this.currentSlideIndex;
            while(this.subTitleLength > 0) {
                await new Promise(resolve => setTimeout(resolve, 1));
                await this.$nextTick();
                this.subTitleLength -= 1;
            }
        },
    },
    subTitle: {
        immediate: true,
        async handler() {
            while(this.subTitleLength < this.title.length) {
                await new Promise(resolve => setTimeout(resolve, 5));
                await this.$nextTick();
                this.subTitleLength += 1;
            }
        }
    },
},
mounted() {
    this.$refs.title.addEventListener('animationend', () => {
        this.activeSlideIndex = this.nextActiveSlideIndex;
        this.out = false;
    });
}}
</script>

<style lang="scss" scoped>
@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
    }
}
@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translateX(10px);
    }
}

.slider-controls {
    position: absolute;
    margin: 0 auto;
    bottom: 30%;
    left: 5vw;
    padding: 0 30px;
    width: calc(39.9375rem + 60px);
    z-index: 15;
    height: fit-content;

    h2 {
        // Layout
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        width: 100%;
        // Typography
        color: #FFF;
        font-family: Glober;
        font-size: 3rem;
        font-style: italic;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;

        animation: fade-in .75s ease-in-out;
        &.out {
            animation: fade-out .5s ease-in-out forwards;
        }
    }

    h4 {
        color: #FFF;
        font-family: Glober;
        font-size: 0.9375rem;
        line-height: 1em;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        display: block;
        height: 0.9375rem;
    }
    .slider-button {
        margin: 1.25rem 0;
    }
    $slider-control-padding-top: .5rem;
    $slider-control-padding-bottom: .5rem;
    .slider-buttons {
        display: flex;
        align-items: center;
        margin-top: (1.88rem - $slider-control-padding-top);
        gap: 10px;
    }

    .slider-control {
        width: 37px;
        height: ($slider-control-padding-top + 0.25rem + $slider-control-padding-bottom);
        // background: #FF6600;
        background-image: linear-gradient(
            180deg,
            rgba(#FF6600, 0) $slider-control-padding-top,
            #FF6600 $slider-control-padding-top,
            #FF6600 ($slider-control-padding-top + 0.25rem),
            rgba(#FF6600, 0) ($slider-control-padding-top + 0.25rem)
        );
        margin-bottom: -$slider-control-padding-bottom;
        cursor: pointer;
        &:not(.active) {
            opacity: 0.5;
        }
    }
}
</style>