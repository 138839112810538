<template>
    <div class="slider-buttons">
        <div class="slider-control"
            v-for="index in nrOfSlides"
            :key="index"
            :class="{active: currentSlide == index - 1}"
            @click="$emit('update:currentSlide', index - 1)">
        </div>
    </div>
</template>

<script>
export default {
    name: 'SliderButtons',
    props: {
        nrOfSlides: {
            type: Number,
            required: true,
        },
        currentSlide: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
$slider-control-padding-top: .5rem;
$slider-control-padding-bottom: .5rem;
.slider-buttons {
    display: flex;
    align-items: center;
    margin-top: (1.88rem - $slider-control-padding-top);
    gap: 10px;
}
.slider-control {
    width: 37px;
    height: ($slider-control-padding-top + 0.25rem + $slider-control-padding-bottom);
    // background: #FF6600;
    background-image: linear-gradient(
        180deg,
        rgba(#FF6600, 0) $slider-control-padding-top,
        #FF6600 $slider-control-padding-top,
        #FF6600 ($slider-control-padding-top + 0.25rem),
        rgba(#FF6600, 0) ($slider-control-padding-top + 0.25rem)
    );
    margin-bottom: -$slider-control-padding-bottom;
    cursor: pointer;
    &:not(.active) {
        opacity: 0.5;
    }
}
</style>
